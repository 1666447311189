/* Home.module.css */
@import '../../colors.css';
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(6px);
  background-size: cover;
  background-repeat: no-repeat;
}
.title {
  font-size: 40px;
  margin: 20px 0;

}
.box {
  background-color: var(--color2);
  border: 1px solid #000000;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.container {
  position: relative;
  text-align: center;
  color: #070101;
  height: 100vh;
  padding: 20px;
}
.description {
  margin: 10px 10;
}
.imageContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
;}

.download {
  margin: 100px 0;
}

.downloadButton {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

.downloadButton:hover {
  background-color: #0056b3;
}

.logoTypography {
  margin-right: 2rem;
  display: none;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: .3rem;
  color: inherit;
  text-decoration: none;
}

.logoTypographyMd {
  display: flex;
}

.iconButton {
  color: inherit;
}

.menu {
  display: none;
}

.menuXs {
  display: block;
}

.pageButton {
  margin: 2rem 0;
  color: white;
  display: block;
}

.avatarIconButton {
  padding: 0;
}

.userMenu {
  margin-top: 45px;
}