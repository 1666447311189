@import '../../colors.css';

body {
    margin: 0;
    padding: 0;
}

.pageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color2); /* outer box color */
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color2); /* inner box color */
  overflow-y: auto;
  border-radius: 10px;
}

.title {
  margin-bottom: px;
}

.inputField {
  margin-bottom: 5px;
}

.redeemButton {
  margin-bottom: 20px;
}

.message {
  margin-top: 20px;
}


.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 90%; 
  height: 90%;
  min-width: 600px; 
  min-height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--color1);
}