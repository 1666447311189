@import '../../colors.css';

.navbar {
  justify-content: space-between;
}

.box {
  display: flex;
}

.boxRight {
  margin-left: auto;
  display: flex;
}

.logoTypography {
  margin-right: 2rem;
  display: none;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: .3rem;
  text-decoration: none;
}

.logoTypographyMd {
  display: flex;
}

.iconButton {
  padding: 0;
}

.menu {
  display: none;
}

.menuXs {
  display: block;
}

.pageButton {
  margin: 2rem 0;
  display: block;
}

.avatarIconButton {
  padding: 0;
}

.userMenu {
  margin-top: 45px;
}