@import '../../colors.css';

@media screen and (max-width: 768px) {
  .form {
    width: 100%; 
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }
}

.pageContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--color2);
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color2);
  overflow-y: auto;
  min-width: 300px;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%; 
  height: 90%;
  min-width: 600px; 
  min-height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--color1);
}

.editavatar {
  width: 400px;
  height: 400px;
  min-width: 100px;
  min-height: 100px;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  min-width: 100px;
  background-color: var(--color1);
  gap: 20px;
}

.infoContainer {
  width: 60%;
  height: 100%;
  display: flex;
  min-width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
