@import '../../colors.css';

body {
    margin: 0;
    padding: 0;
}

.pageContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--color2);
}
.button {
  transition: transform 0.3s ease-in-out;
}
.button:hover {
  transform: scale(1.2);
}

.macContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}
.windowsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 20px;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  background-color: var(--color2);
  overflow-y: auto;
  min-width: 200px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 90%; 
  height: 90%;
  min-width: 600px; 
  min-height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: var(--color1);
}

.titleContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.downloadsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  gap:20px;
}

@media screen and (max-width: 768px) {
  .form {
    width: 100%; 
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }

  .downloadsContainer {
    flex-direction: column;
  }
}