@import '../../colors.css';
.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    background-color: var(--color2);
    overflow-y: auto;
    min-width: 300px;
  }
  
  .row {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .section {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
    border-width: 5px;
    border-radius: 15px;
    background-color: var(--color2);
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 90%; 
    height: 90%;
    min-width: 600px; 
    min-height: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: var(--color1);
  }


.pageContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--color2);
}